import React from 'react';
import { Link } from 'react-scroll';

import './BackToTop.css';

const backToTop = (props) => (
    <Link
        className="buttonToTop"
        activeClass="active"
        to="topPage"
        spy={true}
        smooth={true}
        offset={0}
        duration={1200}
    >
        <i className="up"></i>
    </Link>
);

export default backToTop;