import React from 'react';
import './About.css';

const about = () => (
    <div className="container AboutComponent">

        <div className="AboutInfo">
            <h2>ABOUT</h2>
            <p>Henry Yoong aka KIDZERO(KZ) has left his marks as a diversified music artist in the heart of Toronto.</p>

            <p>With over thousands of events since the beginning of his career, KZ has crafted his style to fall in anything from Progressive, House, Electro, Trance, Techno to Hip Hop, Top 40's and Mash-Ups.</p>

            <p>His passion for music and craft has booked him residency at numerous nightclubs and performed at countless venues across Ontario including Toronto's legendary <strong>"The Guvernment"</strong> complex.</p>
        </div>
        {/* <div className="CurrentResidency">
            <h2>CURRENT RESIDENCY</h2>
            <h3>Modrn NightClub</h3>
            <p className="ResidencyInfo">Every Thursday (Hamilton) - 2019</p>
            <p>15 Hess St S, Hamilton, ON L8P 3M7</p>
        </div> */}
    </div>
);

export default about;