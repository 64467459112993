import React from 'react';
import BackToTop from '../BackToTop/BackToTop';
import './mixCloud.css';

// This musicPlayer will stay at the bottom of the web
const mixCloud = () => {
    return (
        <div className="mixCloud">
            <BackToTop />
            <iframe title="mixcloud-gottabekz" width="100%" height="60" src="https://www.mixcloud.com/widget/iframe/?hide_cover=1&mini=1&feed=%2Fkzmusic%2F" frameBorder="0" ></iframe>
        </div>
    );
}

export default mixCloud;