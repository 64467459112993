import React from 'react';
import { Container } from 'react-bootstrap';
import frontLogo from '../../assets/KZ_LOGO.svg';
import mixCloudIcon from '../../assets/social/social-mixcloud.png';
import facebookIcon from '../../assets/social/social-facebook.png';
import instaIcon from '../../assets/social/social-insta.png';

import './Logo.css';

const logo = () => (
    <Container className="LogoComponent">
        <div className="flex-container">
            <a href="https://www.facebook.com/gottabekz" target="_blank" rel="noopener noreferrer"><img className="SocialIcons" src={facebookIcon} alt="facebook-icon" /></a>
            <a href="https://www.instagram.com/gottabekz/" target="_blank" rel="noopener noreferrer"><img className="SocialIcons" src={instaIcon} alt="insta-icon" /></a>
            <a href="https://www.mixcloud.com/kzmusic/" target="_blank" rel="noopener noreferrer"><img className="SocialIcons" src={mixCloudIcon} alt="mixcloud-icon" /></a>
        </div>
        <div style={{ paddingTop: "120px", paddingBottom: "190px" }}>
            <div >
                <img className="Logo" alt="logo" src={frontLogo} />
                <h1 className="Name">KIDZERO</h1>
                <h3 className="PersonalTag">NIGHTCLUBS | PRIVATE EVENTS | WEDDINGS</h3>

                <div className="BookingComp">
                    <p style={{ marginBottom: "5px" }}>BOOKING INQUIRY:</p>
                    <strong><a className="BookingButton" href="mailto:booking@gottabekz.com">BOOKING@GOTTABEKZ.COM</a></strong>
                </div>
            </div>
        </div>
    </Container>
);

export default logo;