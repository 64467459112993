import React from 'react';
import './Footer.css';

const footer = () => (
    <div className="FooterComponent">
        <br/>
        <br/>
        <a className="FooterButton" href="https://www.andrewnguyen.io" target="_blank" rel="noopener noreferrer">Website Creation by: Andrew Nguyen</a>
        <p style={{color:"white"}}>Developer x Designer</p>
        <br/>
        <br/>
    </div>
);

export default footer;