import React, { Component } from 'react';
import MainBanner from './components/MainBanner/MainBanner';
import Logo from './components/Logo/Logo';
import About from './components/About/About';
import MixCloud from './components/MixCloud/mixCloud';
import MusicEvents from './components/MusicEvents/MusicEvents';
import Residencies from './components/MusicEvents/Residencies/Residencies';
import Footer from './components/Footer/Footer';

import './App.css';

class App extends Component {

  render() {
    return (
      <div className="App">
        <div id="topPage"></div>
        <MainBanner />
        <Logo />
        <About />
        <MusicEvents />
        <Residencies />
        <MixCloud />
        <Footer />
      </div>
    );
  }
}

export default App;
