import React from 'react';
import {Container, Row, Col } from 'react-bootstrap';

import './MusicEvent.css';

const musicEvent = (props) => (
    <Container className="textInfo">
        <Row>
            <Col sm={3}>{props.date}</Col>
            <Col sm={3}>{props.location}</Col>
            <Col sm={6}>{props.eventName}</Col>
        </Row>
        <hr className="lineBreak"/>
    </Container>
);

export default musicEvent;