import React, { Component } from 'react';
import { Row, Container } from 'react-bootstrap';
import axios from 'axios';
import MusicEvent from '../MusicEvent/MusicEvent';

import dataResidencies from '../../../pastResidency.json';

import './Residencies.css';

class Residencies extends Component {
    state = {
        residencies: []
    }

    componentDidMount() {
        // console.log(this.props);
        // axios.get('https://api.myjson.com/bins/dvzve')
        //     .then(response => {
        //         const residencies = response.data;
        //         const updatedResidencies = residencies.map(residency => {
        //             return {
        //                 ...residency,
        //                 author: 'KZ'
        //             }
        //         })
        //         this.setState({ residencies: updatedResidencies});
        //         // console.log(updatedResidencies);
        //     })
        //     .catch(error => {
        //         // console.log(error);
        //     });

        const residencies = dataResidencies;
        const updatedResidencies = residencies.map(residency => {
            return {
                ...residency,
                author: 'KZ'
            }
        })
        this.setState({ residencies: updatedResidencies });
    }

    render() {
        let residencies = this.state.residencies.map(residency => {
            return (
                <Row key={residency.id}>
                    <MusicEvent
                        date={residency.date}
                        location={residency.location}
                        eventName={residency.eventName}
                    />
                </Row>
            )
        })
        return (
            <Container className="ResidenciesComponent">
                <Row> <h2 className="PastEventName">PAST RESIDENCIES</h2> </Row>
                {residencies}
            </Container>
        );
    }
}

export default Residencies;