import React, { Component } from 'react';

import './MainBanner.css';
import background1 from '../../assets/background/kz-night.jpg';
import background2 from '../../assets/background/henry-fly.jpg';
import background3 from '../../assets/background/henry-newyears.jpg';
import background4 from '../../assets/background/henry-photo.jpg';
import background5 from '../../assets/background/henry-sick.jpg';
import background6 from '../../assets/background/henry-stage.jpg';
import background7 from '../../assets/background/henry-photo2.jpg';

let slideIndex = 0;

class MainBanner extends Component {

    showSlides = () => {
        let i;
        let slides = document.getElementsByClassName("mySlides");

        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slideIndex++;
        if (slideIndex > slides.length) slideIndex = 1;
        slides[slideIndex - 1].style.display = "block";
    }

    startCarousel = () => {
        this.carouselInterval = setInterval(() => {
            this.showSlides();
        }, 9000);
    };

    componentDidMount() {
        this.startCarousel();
        document.getElementsByClassName("mySlides")[6].style.display= "block";
    }

    componentWillUnmount() {
        clearInterval(this.carouselInterval);
    }

    render() {
        return (
            <div className="MainBanner">
                <div className="slideShowContainer">
                    <div className="mySlides fade">
                        <img className="backgroundImage" alt="backgroundImage1" src={background1} />
                    </div>
                    <div className="mySlides fade">
                        <img className="backgroundImage" alt="backgroundImage2" src={background2} />
                    </div>
                    <div className="mySlides fade">
                        <img className="backgroundImage" alt="backgroundImage3" src={background3} />
                    </div>
                    <div className="mySlides fade">
                        <img className="backgroundImage" alt="backgroundImage4" src={background4} />
                    </div>
                    <div className="mySlides fade">
                        <img className="backgroundImage" alt="backgroundImage5" src={background5} />
                    </div>
                    <div className="mySlides fade">
                        <img className="backgroundImage" alt="backgroundImage6" src={background6} />
                    </div>
                    <div className="mySlides fade">
                        <img className="backgroundImage" alt="backgroundImage7" src={background7} />
                    </div>
                </div>
            </div>
        );
    };
};

export default MainBanner;