import React, { Component } from 'react';
import { Row, Container } from 'react-bootstrap';
import axios from 'axios';
import MusicEvent from './MusicEvent/MusicEvent';

import dataEvents from '../../pastEvents.json';

import './MusicEvents.css';

class MusicEvents extends Component {
    state = {
        musicEvents: []
    }

    componentDidMount() {
        // console.log(this.props);
        // axios.get('https://api.myjson.com/bins/y20iu')
        //     .then(response => {
        //         const musicEvents = response.data;
        //         const updatedMusicEvents = musicEvents.map(musicEvent => {
        //             return {
        //                 ...musicEvent,
        //                 author: 'KZ'
        //             }
        //         })
        //         this.setState({ musicEvents: updatedMusicEvents });
        //         // console.log(updatedMusicEvents);
        //     })
        //     .catch(error => {
        //         // console.log(error);
        //     });
        const musicEvents = dataEvents;
        const updatedMusicEvents = musicEvents.map(musicEvent => {
            return {
                ...musicEvent,
                author: 'KZ'
            }
        })
        this.setState({ musicEvents: updatedMusicEvents });
    }

    render() {
        let musicEvents = this.state.musicEvents.map(musicEvent => {
            return (
                <Row key={musicEvent.id}>
                    <MusicEvent
                        date={musicEvent.date}
                        location={musicEvent.location}
                        eventName={musicEvent.eventName}
                    />
                </Row>
            )
        })
        return (
            <Container className="MusicEventsComponent">
                <Row> <h2 className="PastEventName">PAST EVENTS</h2> </Row>
                <Row key={9999}>
                    <MusicEvent
                        date="DATE"
                        location="LOCATION"
                        eventName="EVENTS"
                    />
                </Row>
                {musicEvents}
            </Container>
        );
    }
}

export default MusicEvents;